.navbar-lighter{
    background-color: #DAD7CD;
    border-bottom: 1px solid #344E41;
}

.btn-lighter {
    background-color: #3A5A40;
    color: white;
    border-color: #344E41;
}

.btn-lighter-secondary{
    background-color: #d8e2da;
    color: #344E41;
    border-color: #344E41;
}
.btn-lighter:hover {
    background-color: #344E41;
    color: white;
    border-color: #344E41;
}

.btn-lighter:active {
    background-color: #A3B18A !important;
    color: black  !important;
    border-color: #344E41 !important;
}

.warning-lighter{
    color: darkred;
    font-weight: bolder;
}

.warningbox-lighter{
    background-color: lightyellow;
    border: black 1px solid;
    border-radius: 5px;
    padding: 5px;
    margin: 5px;
}

.modal-lighter {
    transform: translate(0%, 15%);
    padding-bottom: 5px;
    padding-top: 5px;
    background-color: #DAD7CD;
    border-radius: 10px;
}

.holder-lighter {
    background: rgba(0, 0, 0, 0.5);
    animation: faderlight 2s infinite;
    display: inline-block;
    width: 100%;
    border-radius: 5px;
}

@keyframes faderlight {
    0% {
      background: rgba(0, 0, 0, .25);
    }
    50% {
      background: rgba(255, 255, 255, 0);
    }
    100% {
      background: rgba(0, 0, 0, .25);
    }
  }

/* Darker */
a.dark{
    color: #DAD7CD;
}

button.dark.btn.btn-link {
    color: #DAD7CD;
}

.navbar-dark{
    background-color: #302f2c;
    border-bottom: 1px solid #8ad0ad;
}

.btn-dark {
    background-color: #344E41;
    color: #DAD7CD;
    border-color: #344E41;
}

.btn-dark:hover {
    background-color: #344E41;
    color: #DAD7CD;
    border-color: #99e3be;
}

.btn-dark:active {
    background-color: #A3B18A !important;
    color: black  !important;
    border-color: #344E41 !important;
}

.warning-dark{
    color: rgb(255, 185, 185);
    font-weight: bolder;
}

.warningbox-dark{
    color: rgb(255, 185, 185);
    background-color: rgb(55, 0, 0);
    border: black 1px solid;
    border-radius: 5px;
    padding: 5px;
    margin: 5px;
  }

.text-dark{
    color: #DAD7CD !important;
}

.modal-dark {
    transform: translate(0%, 15%);
    padding-bottom: 5px;
    padding-top: 5px;
    background-color: black;
  }

.table-dark{
    --bs-table-bg: transparent;
}

.holder-dark {
    background: rgba(145, 145, 145, 0.5);
    animation: faderdark 2s infinite;
    display: inline-block;
    width: 100%;
    border-radius: 5px;
}

@keyframes faderdark {
    0% {
      background: rgba(145, 145, 145, 0.5);
    }
    50% {
      background: rgba(145, 145, 145, 0);
    }
    100% {
      background: rgba(145, 145, 145, 0.5);
    }
  }
/*  */