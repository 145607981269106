.App {
  height: 100vh;
}

.App.dark {
  background-color: #353a44;
  color: white;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.cap::first-letter {
  font-size: large;
  text-transform: capitalize;
}

.label {
  font-weight: bolder;
}

.data {
  padding-left: 10px;
}

.pageTitle {
  font-size: larger;
  font-weight: bolder;
}

.warning {
  background-color: darkred;
  color: yellow;
  border-radius: 5px;
  padding: 5px;
  text-align: center;
  font-weight: bold;
}

.loader {
  height: 100% !important;
  width: 100% !important;
  position: absolute;
  background-color: rgb(211 211 211 / 75%);
  z-index: 1000;
  left: 0px;
}

.notice {
  background-color: lightyellow;
  border: solid 1px gray;
  border-radius: 5px;
  padding: 5px;
  text-align: center;
  font-weight: bold;
}

.stickynote {
  background-color: lightyellow;
  border-radius: 5px;
  border-color: lightgray;
  text-align: center;
  rotate: -14deg;
  max-width: 20em;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
}