.header{
    background-color: #3A5A40;
    color: white;
    border-radius: 5px;
    margin: 2px;
}

.time {
    font-size: smaller;
    position: relative;
    top: -1em;
}

.apptSlot{
    background-color: #cccccc;
    border-radius: 5px;
    margin: 2px;
    text-align: left;
    height: 30px;
}

.reserved{
    background-color: #555555;
    color: #444444;
}

.selected{
    background-color: #8ad6ab;
    color: #444444;
}

div.scroll {
    padding-top: 1em;
    height: 40vh;
    overflow-x: hidden;
    overflow-y: auto;
}